<template>
  <OnBoarding :maxContentWidth="420">
    <template>
      <CRow>
        <CCol col="12">
          <OnBoardingHeader :title="$gettext('Verify your email')"/>
        </CCol>
        <CCol col="12 mb-4">
          <div class="text-center" style="font-size: 16px">
            <translate>We sent a verification code to:</translate>&nbsp;
            <span class="font-weight-semi-bold">{{ email }}</span></div>
        </CCol>
        <CCol col="12">
          <div class="d-flex justify-content-center verification-code">
            <CInput :ref="`code1`"
                    v-model="code1"
                    :isValid="isValid"
                    @keyup="onCodeUpdate($event, 1)"
                    @paste="onPaste($event)"
                    maxlength="1" class="mx-1"/>
            <CInput :ref="`code2`"
                    v-model="code2"
                    :isValid="isValid"
                    @keyup="onCodeUpdate($event, 2)"
                    @paste="onPaste($event)"
                    maxlength="1" class="mx-1"/>
            <CInput :ref="`code3`"
                    v-model="code3"
                    :isValid="isValid"
                    @keyup="onCodeUpdate($event, 3)"
                    @paste="onPaste($event)"
                    maxlength="1" class="mx-1"/>
            <CInput :ref="`code4`"
                    v-model="code4"
                    :isValid="isValid"
                    @keyup="onCodeUpdate($event, 4)"
                    @paste="onPaste($event)"
                    maxlength="1" class="mx-1"/>
          </div>
          <div class="text-center mb-3" style="min-height: 21px">
            <CSpinner v-if="validating" size="sm" color="primary" class="d-block mx-auto mb-1"/>
            <span v-show="isValid !== undefined && !isValid" class="text-danger font-weight-semi-bold" v-translate>Invalid code</span>
          </div>

          <PAlert icon="cipInfo"
                  content-align="align-items-center"
                  :disabled="timeout > 0"
                  color="primary"
                  :text="$pgettext('auth.code', 'Haven\'t received the code?')"
                  :class="{'invisible': validating}">
            <template #cta>
              <CLink @click="resendCode" style="font-size: 12px" class="mr-1">
                <span v-if="timeout > 0"><span v-translate>Retry in</span> {{ timeout }}s</span>
                <span v-else v-translate>Resend code</span>
              </CLink>
            </template>
          </PAlert>
        </CCol>
      </CRow>
    </template>
  </OnBoarding>

</template>

<script>
import {mapActions} from "vuex";
import {methods} from "@/domain/login/mixin";
import OnBoarding from "@/domain/login/OnBoarding.vue";
import OnBoardingHeader from "@/domain/login/components/OnBoardingHeader.vue";
import _ from "lodash";
import PAlert from "@/components/PAlert.vue";

export default {
  name: 'Validate',
  components: {PAlert, OnBoardingHeader, OnBoarding},
  emits: ['complete', 'cancel'],
  data() {
    return {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      timeout: 0,
      email: null,
      validating: false,
      validationError: undefined,
      isPasting: false
    }
  },
  computed: {
    completeCode() {
      return `${this.code1}${this.code2}${this.code3}${this.code4}`
    },
    isValid() {
      if (!_.isBoolean(this.validationError)) return undefined
      return !this.validationError
    },
  },
  mounted() {
    this.$refs.code1.$el.children[0].focus()
    this.email = this.$route.params.email
    this.resetTimeout()

    this.$refs.code1.$el.children[0].addEventListener('paste', this.onPaste);
    this.$refs.code2.$el.children[0].addEventListener('paste', this.onPaste);
    this.$refs.code3.$el.children[0].addEventListener('paste', this.onPaste);
    this.$refs.code4.$el.children[0].addEventListener('paste', this.onPaste);
  },
  methods: {
    ...methods,
    ...mapActions('login', [
      'resendValidationCode',
      'validateCode'
    ]),
    updateTimeout() {
      if (this.timeout > 0) {
        setTimeout(() => {
          this.timeout--
          this.updateTimeout()
        }, 1000)
      }
    },
    resetTimeout() {
      this.timeout = 30
      this.updateTimeout()
    },
    resendCode() {
      this.resetTimeout()
      this.resendValidationCode({email: this.email})
    },
    async onPaste(event) {
      this.isPasting = true
      const paste = (event.clipboardData || window.clipboardData).getData('text')

      this.code1 = paste[0]
      this.code2 = paste[1]
      this.code3 = paste[2]
      this.code4 = paste[3]

      await this.$nextTick()
      await this.delay(100)

      this.$refs.code4.$el.children[0].focus()
      await this.onCodeUpdate(event, 4)
      this.isPasting = false

      event.preventDefault()
    },
    async onCodeUpdate(event, index) {
      if (this.handleKeys(event, index)) return
      if (this.completeCode.length < 4) {
        this.focusNextInput(index)
        return
      }
      await this.validateCodeInput()
    },
    handleKeys(event, index) {
      if (event.key === 'Backspace') {
        if (index > 1) {
          this.focusPreviousInput(index);
          this.validationError = undefined
        }
        return true;
      }
      if (event.key === 'ArrowLeft') {
        this.focusPreviousInput(index);
        return true;
      }
      if (event.key === 'ArrowRight') {
        this.focusNextInput(index);
        return true;
      }
      return false;
    },
    async focusPreviousInput(index) {
      await this.$nextTick()
      const prevInput = this.$refs[`code${index - 1}`].$el.children[0]
      prevInput.focus()
      prevInput.select()
    },
    focusNextInput(index) {
      if (index < 4) {
        this.$refs[`code${index + 1}`].$el.children[0].focus()
      }
    },
    async validateCodeInput() {
      if (this.isPasting || this.validating) return
      this.validating = true
      this.validationError = undefined
      try {
        await this.validateCode({code: this.completeCode, email: this.email});
        this.validationError = false
        await this.delay(1500)
        await this.afterLogin()
      } catch (e) {
        this.validationError = true
      } finally {
        this.validating = false
      }
    }
  }
}
</script>